import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";

import FacebookWidget from '@/components/widgets/facebook/index.vue';
import GoogleWidget from '@/components/widgets/google/index.vue';


export default {
  name: "register",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
        checkbox: false,
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      name: {
        required,
      },
      email: {
        required,
        email,
        maxLength: maxLength(50),
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
      },
      password_confirmation: {
        maxLength: maxLength(20),
        required,
        sameAsPassword: sameAs('password')
      },
      checkbox: {
        checked: value => value === true
      },
    }
  },
  computed: {
    ...mapGetters({}),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;

    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    checkboxErrors() {
      let error = [];
      if (!this.$v.payload.checkbox.$dirty) {
        return error;
      }
      if (!this.$v.payload.checkbox.checked) {
        error.push(this.$t('validationRequired'));
      }
      
      if (this.validationErrors.checkbox) {
        this.validationErrors.checkbox.forEach((row) => {
          error.push(row);
        })
      }
      return error;

    },
  },
  created() {

  },
  components: {
    GoogleWidget,
    FacebookWidget
  },
  methods: {
    ...mapMutations({
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupLogin: `auth/CHANGE_POPUP_LOGIN`,
    }),
    ...mapActions({
      registration: `auth/REGISTRATION`,
      singIn: `auth/GET_TOKEN`,
      fetchFavouriteList: `favorites/GET_FAVORITES_LIST`,
      fetchUser: `profile/FETCH_USER_DATA`,
    }),
    goToLogin() {
      this.changePopupRegister(false)
      this.changePopupLogin(true)
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.registration(this.payload).then(() => {
          this.$toasted.success(this.$t('successRegister'));
          let obj = {}
          obj.email = this.payload.email
          obj.password = this.payload.password
          this.singIn(obj).then(() => {
            this.fetchFavouriteList()
            // this.$router.push({name: 'profile'}).catch(() => {
            //   console.log()
            // })
            this.fetchUser();
          }).catch(error => {
            if (error.response.status === 418) {
              this.$toasted.error(this.$t("authFail"));
            }
            if (error.response.status === 422) {
              this.$toasted.error(this.$t("authFailData"));
            }
          })
          this.changePopupRegister(false)
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(this.$t("alreadyExist"));
          }
        })
      }
    }
  }
}